import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ninja-select-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './select-button.component.html',
  styleUrl: './select-button.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectButtonComponent),
      multi: true,
    },]
})
export class SelectButtonComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() data: { key: string; value: string }[] = [];
  @Input() size: 'normal' | 'bigger' = 'normal';
  @Input() grid: number = 1;
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();

  itemSelect: string | null = null;

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if needed
  }

  writeValue(obj: any): void {
    this.itemSelect = obj;
  }

  selectOption(item: { key: string; value: string }): void {
    this.itemSelect = item.key;
    this.onChange(this.itemSelect); // Notify Angular forms that the value has changed
    this.onTouched(); // Notify Angular forms that the control has been touched
    this.changed.emit(this.itemSelect);
  }

  // Placeholder methods for the onChange and onTouched functions
  private onChange: (value: any) => void = () => {
  };
  private onTouched: () => void = () => {
  };
}

<div class="checkbox" [ngClass]="{'checkbox-circle':variant === 'circle'}">
  <input
    type="checkbox"
    [checked]="value"
    (change)="onCheckboxChange($event)"
    [name]="label"
    [disabled]="disabled"
    id="checkboxId"
  />
  @if(label){
    <label [for]="label" class="checkbox__label">{{ label }}</label>
  }
</div>

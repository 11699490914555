<div class="select" (mouseenter)="state = 0" (mouseleave)="state = 1" [ngClass]="{
        '': variant === 'default',
        'select__bordered': variant === 'bordered',
        'select__bold': variant === 'bold'
      }" [class.active]="showMenu">
  <label class="select__label" for="select-btn">{{ label }}</label>
  <button
    [disabled]="isDisabled"
    id="select-btn"
    type="button"
    class="select__button"
    (click)="showMenu = !showMenu"
  >
    <div class="select__texts">
      @if (multiple) {
        @for (item of selectedItems; track item) {
          <span class="select__text">{{ item?.label + ',' }}</span>
        } @empty {
          {{ placeholder }}
        }
      } @else {
        @if (selectedItem?.label){
          <span class="select__selected">{{selectedItem?.label}}</span>
        } @else {
          {{placeholder}}
        }
      }
    </div>
    <i class="material-icons select__icon" [class.text-text900]="selectedItem?.label">{{ showMenu ? 'expand_less' : 'expand_more' }}</i>
  </button>
  <div class="select__content" [class.show]="showMenu" [ngClass]="{'top-60':!label, 'top-80':label}">
    @if (multiple) {
      <a class="select__item" (click)="selectAll(data)">
        <ninja-checkbox variant="circle" [class.select__item-all]="" [value]="selectedItems.length === data.length"
                        label="Select All"></ninja-checkbox>
      </a>
    }
    @for (d of data; track d) {
      <a class="select__item" (click)="multiple ?  toggleSelection(d) : valueChange(d)"
         [class.active]="multiple ? selectedItems.includes(d) : selectedItem === d">
        @if (multiple) {
          <ninja-checkbox [value]="selectedItems.includes(d)" [label]="d.label"></ninja-checkbox>
        } @else {
          {{ d.label }}
        }
      </a>
    }
  </div>
</div>

<div class="toggle-switch" [ngClass]="{ 'toggle-switch--checked': value }">
  <label
    class="toggle-switch__label"
    [ngClass]="labelClasses"
    [for]="label">
    @if (!!labelIcon) {
      <i
        class="iconsax"
        [ngClass]="labelIconClasses"
        [attr.icon-name]="labelIcon"></i>
    }

    {{ label }}
  </label>
  <div class="toggle-switch__field">
    <input
      class="switch-input peer"
      type="checkbox"
      [id]="label"
      [checked]="value"
      (change)="handleChange($event)"
      (blur)="onTouched()"
      [disabled]="disabled"
    />
    <span class="switch-icon"></span>
  </div>
</div>

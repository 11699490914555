import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TextboxComponent } from '@component/textbox/textbox.component';
import { SpinnerComponent } from '@component/spinner/spinner.component';
import { DropdownMouseState } from '@component/select/select.component';

@Component({
  selector: 'ninja-autocomplete',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ],
  templateUrl: './autocomplete.component.html',
  imports: [
    FormsModule,
    TextboxComponent,
    ReactiveFormsModule,
    SpinnerComponent
  ],
  styleUrl: './autocomplete.component.scss'
})
export class AutocompleteComponent implements ControlValueAccessor {
  @Input() options: { key: string; value: string }[] = [];
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() loading: boolean = false;
  @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  filteredOptions: any[] = [];
  private _value: any;
  formControl: FormControl = new FormControl();
  showMenu: boolean;
  state: DropdownMouseState;

  @HostListener('document:click') clickedOutside() {
    if (this.state == DropdownMouseState.outside) {
      this.showMenu = false;
    }
  }

  constructor() {
    this.showMenu = false;
    this.state = DropdownMouseState.outside;
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  writeValue(value: { key: string; value: string }): void {
    this._value = value.key;
    this.formControl.setValue(value.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set value(val: any) {
    if (val !== this._value) {
      this._value = val;
      this.onChange(val);
    }
  }

  get value(): any {
    return this._value;
  }

  selectOption(option: { key: string; value: string }) {
    this.showMenu = false;
    this.formControl.setValue(option.value);
    this.value = option;
    this.filteredOptions = [];
    this.onTouched();
    this.onChange(option);
    this.onSelectionChange.emit(option);
  }

  keyup() {
    this.showMenu = true;
    this.onKeyup.emit(this.formControl.value);
  }
}

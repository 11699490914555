<label class="select-button__label" for="select-button">{{ label }}</label>
<div class="select-button" [ngClass]="{
    'grid-cols-1': grid === 1,
    'grid-cols-2': grid === 2,
    'grid-cols-6': grid === 6,
}">
  @for (item of data; track item; let first = $first; let last = $last) {
    <button
      id="select-button"
      (click)="selectOption(item)"
      [class.select-button__first]="first"
      [class.select-button__last]="last"
      [class.select-button__active]="itemSelect === item.key"
      [class.select-button__bigger]="size === 'bigger'"

      class="select-button__main"
      type="button"
    >
      {{ item.value }}
    </button>
  }
</div>

<div class="autocomplete" (mouseenter)="state = 0" (mouseleave)="state = 1">
  <ninja-textbox autocomplete="nope" [formControl]="formControl" [label]="label" [placeholder]="placeholder"
                 (keyup)="keyup()"></ninja-textbox>
  @if (formControl.touched && showMenu) {
    <div class="autocomplete__options">
      @if (loading) {
        <div class="autocomplete__loading">
          <ninja-spinner></ninja-spinner>
        </div>
      } @else {
        @for (option of options; track option) {
          <div class="autocomplete__option" (click)="selectOption(option)">
            {{ option.value }}
          </div>
        }
      }
    </div>
  }
</div>

import { booleanAttribute, Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxComponent } from '@component/checkbox/checkbox.component';
import { data } from 'autoprefixer';

export enum DropdownMouseState {
  inside,
  outside,
}

export interface IDropdownItem {
  label: string;
  value: any;
}

@Component({
  selector: 'ninja-select',
  standalone: true,
  imports: [CommonModule, FormsModule, CheckboxComponent],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ]
})
export class SelectComponent implements ControlValueAccessor {
  @Input() data!: Array<IDropdownItem>;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() variant: 'default' | 'bordered' | 'bold' = 'default';
  @Input({transform: booleanAttribute}) multiple: boolean = false;

  @Output() change:EventEmitter<any> = new EventEmitter<any>();

  showMenu: boolean;
  isDisabled: boolean;
  selectedItem!: IDropdownItem | undefined;
  selectedItems: IDropdownItem[] = [];

  propagateChange = (_: any) => {
  };

  state: DropdownMouseState;

  @HostListener('document:click') clickedOutside() {
    if (this.state == DropdownMouseState.outside) {
      this.showMenu = false;
    }
  }

  constructor() {
    this.showMenu = false;
    this.isDisabled = false;
    this.state = DropdownMouseState.outside;
  }

  toggleSelection(item: IDropdownItem) {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }
    this.propagateChange(this.selectedItems.map(i => i.value));
  }

  selectAll(data: IDropdownItem[]) {
    if (this.selectedItems.length === data.length) {
      // If all items are already selected, deselect them all
      this.selectedItems = [];
    } else {
      // Otherwise, select all items
      this.selectedItems = [...data];
    }
    console.log(this.selectedItems.map(i => i.value));
    this.propagateChange(this.selectedItems.map(i => i.value));
  }

  valueChange(item: IDropdownItem) {
    if (this.selectedItem !== item) {
      this.selectedItem = item;
    } else {
      this.selectedItem = {value: '', label: ''};
    }
    this.propagateChange(item.value);
    this.change.emit(item.value);
    this.showMenu = false;
  }

  instanceOfIDropdownItem(object: any): object is IDropdownItem {
    return object.value !== undefined;
  }

  writeValue(value: any): void {
    if (!this.multiple) {
      if (value == null) {
        this.selectedItem = {value: '', label: ''};
      } else if (this.instanceOfIDropdownItem(value)) {
        this.selectedItem = value;
      } else {
        if (this.data) {
          this.selectedItem = this.data.find((w) => w.value == value);
        }
      }
    } else {
      if (value == null) {
        this.selectedItems = [];
      } else if (this.instanceOfIDropdownItem(value)) {
        this.selectedItems.push(value);
      } else {
        if (this.data) {
          this.selectedItems = this.data.filter(item => value.includes(item.value));
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
